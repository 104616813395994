<template>
  <div class="w-100 position-relative pdf-wrapper">
    <CCard class="">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <div id="pdf-2" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.II.1 <br>
                  Văn bản dự kiến chương trình xúc tiến đầu tư hàng năm</strong>
                <p class="font-italic">(Áp dụng đối với chương trình xúc tiến đầu tư của Bộ/Ủy ban nhân dân cấp tỉnh theo quy định tại Điểm a, Khoản 2, Điều 93 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                <hr>
              </div>
              <div class="row fs-16">
                <div class="col text-center">
                  <p class="font-weight-bold text-uppercase">{{ item.tenBo_UBNDTinh }}</p>
                  <hr width="150px" style="border: 1px double">
                  <p class="mt-5 mr-3">Số: {{ item.soVanBan }}</p>
                  <div class="" style="width: 13em; margin-left: 6em">
                    <p class="font-weight align-middle">V/v dự kiến chương trình xúc tiến đầu tư năm {{ item.nam_ChuongTrinh }}</p>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</span>
                  <p class="font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                  <hr width="180px" style="border: 1px double">
                  <p class="font-weight font-italic" style="float: right">
                    {{ item.diaDiem }}, ngày {{ getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm
                    {{ getYear(item.ngayVanBan) }}
                  </p>
                </div>
              </div>
              <div class="text-center">
                <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
              </div>
              <div class="ml-3">
                <p class="font-weight-bold" style="text-indent: 3em">I. Dự kiến chương trình xúc tiến đầu tư năm {{ item.nam_ChuongTrinh }}</p>
                <p style="text-indent: 3em">1. Đánh giá sơ bộ hoạt động xúc tiến đầu tư của năm trước</p>
                <p style="text-indent: 4em">{{ item.danhGiaHoatDongNamTruoc }}</p>
                <p style="text-indent: 3em">2. Quan điểm, định hướng, mục tiêu của chương trình xúc tiến đầu tư</p>
                <p style="text-indent: 4em">{{ item.quanDiemDinhHuongMucTieu }}</p>
                <p style="text-indent: 3em">3. Dự kiến chương trình xúc tiến đầu tư <span class="font-italic">(trong đó nêu rõ lý do, sự phù hợp…)</span></p>
                <p style="text-indent: 4em">{{ item.duKienChuongTrinh }}</p>
              </div>
              <div class="ml-3">
                <p class="font-weight-bold" style="text-indent: 3em">II. Tổ chức thực hiện</p>
                <p style="text-indent: 3em">{{ item.toChucThucHien }}</p>
              </div>
              <div class="ml-3">
                <p class="font-weight-bold" style="text-indent: 3em">III. Kiến nghị, đề xuất</p>
                <div class="row">
                  <div class="col">
                    <p class="font-italic">Phụ lục gửi kèm</p>
                    <p>(i) Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư năm
                      {{ item.nam_ChuongTrinh }} <span class="font-italic">(Mẫu C.II.2)</span>;</p>
                    <p>(ii) Danh mục dự án đầu tư đang có nhà đầu tư quan tâm <span class="font-italic">(Mẫu A.IV.4)</span>.</p>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col ml-3 mt-3">
                  <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                  <span class="font-weight">- Như trên;</span> <br>
                  <span class="font-weight">- Lưu: VT,.......</span> <br>
                </div>
                <div class="col">
                  <div class="float-right mr-5" style="text-align: center">
                    <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan</span> <br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br><br><br>
                    <br>
                    <br>
                  </div>
                </div>
              </div>
            </div>
            <div class="print-btn close-button">
              <button class="print-button" @click="print">
                <span class="print-icon"></span></button>
            </div>
          </div>
        </div>
      </div>
    </CCard>
  </div>
</template>

<script>
import { displayLabel } from '@/shared/appConstants'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import moment from 'moment'
import appConfig from '@/shared/appConfig'

export default {
  name: 'DeXuatCTXTDTPrint',
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDT',
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      item: {}
    }
  },
  computed: {
  },
  methods: {
    displayLabel,
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    getDate: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD/MM/YYYY') : '',
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : '',
    back () {
      this.$router.push({ path: '/de-xuat-ct-xtdt' })
    },
    getLocalStorage () {
      const jsonData = localStorage.getItem('de_xuat_ct_xtdt_dia_phuong')
      this.item = JSON.parse(jsonData)
      // console.log(this.item)
    },
    print () {
      window.print()
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getItem(this.$route.params.id)
      // if (this.item.trangThai === 3) await this.getYKienPheDuyetGanNhat(this.$route.params.id)
    } else {
      await this.getLocalStorage()
    }
    if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
  }
}
</script>

<style scoped>

</style>
